
// ----------------------------------------
//  Double Click Stylings
// ----------------------------------------

.double-click {
    color: var(--#{$prefix}light);
    width: 100%;
    height: 450px;
    display: flex;
    padding: $spacer * 1.5 $spacer;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: var(--#{$prefix}dark);

    @include media-breakpoint-up(md) {
        padding: $spacer * 2 $spacer * 3;
    }

    .double-click-title {
        font-size: 1.6rem;
        margin-bottom: $spacer;
    }
    .double-click-description {
        font-size: 1.1rem;
        font-weight: 300;
    }
    .double-click-button {
        @extend .btn;
        @extend .btn-light;

        margin-top: auto;
        border-radius: 0;
    }
}
