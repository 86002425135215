
// ----------------------------------------
//  Form Control Stylings
// ----------------------------------------

.form-control {
    border-color: $gray-200;
    background-color: $white;
    border-radius: 5px;
    border: 2px solid #dfdfdf;

    &:hover,
    &:focus {
        box-shadow: none;
    }
    &:focus {
        border-color: var(--#{$prefix}primary);
        background-color: $gray-200;
    }

    .dark & {
        color: $gray-300;
        border-color: $gray-600;
        background-color: $gray-900;

        &:focus {
            border-color: var(--#{$prefix}primary);
            background-color: $gray-900;
        }
    }
}


// ----------------------------------------
//  Form Group Stylings
// ----------------------------------------

.form-group {
    margin-bottom: $spacer;y

    &:last-child {
        margin-bottom: 0;
    }
}

// custom checkboxes
.form-control,
.form-check-input {
    border: 1px solid $gray-600;
}

.large-checkboxes {
    width: 2rem;
    height: 2rem;
    margin-right: 2rem;
}

#waiter {
    top: 0;
    opacity: .6;
    img {
        opacity: .6;
    }
}

// consultation referral
section#form-section:has( + div.disable-cs-form) {
    -webkit-filter: blur(5px);
    filter: blur(2px);
    opacity: .5;
}

#form-disable {
    top: 197px;
    h3 {
        position: absolute;
        top: 0;
        padding: 2rem;
    }
    a {
        color: $white;
    }
}

#waiter,
#form-disable {
    position: fixed;
    bottom:0;
    width: 100%;
    height: 100vh;
    background-color: $black;
    z-index: 10000;
    opacity: .5;
}
