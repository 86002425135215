
// ----------------------------------------
//  ColorPicker Stylings
// ----------------------------------------

.colorpicker {
    label {
        @extend .btn;
        cursor: pointer;
        padding: $spacer * 0.39 $spacer * 1.5;
        border: 0;
        opacity: 0.5;
        background-color: transparent;
        border-radius: 5px;
        transition: opacity 200ms ease, background-color 200ms ease;
        background-color: rgba(0, 0, 0, 0.15);

        &:hover {
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.15);
        }
        
        span {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        svg {
            margin-top: 4px;
            vertical-align: top;
        }

        input {
            display: none;
    
            & + span {
                display: block;
            }
            & + span + span {
                display: none;
            }

            &:checked {
                & + span {
                    display: none;
                }
                & + span + span {
                    display: block;
                }
            }
        }
    }

    .dark & {
        label {
            svg {
                color: var(--#{$prefix}light);
            }
        }
    }
}

.navbar .colorpicker {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: rgba(0, 0, 0, 0.15);

    @include media-breakpoint-down(md) {
        top: 7px;
        right: -1.0rem;
        position: absolute;
        width: 32px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .sticky-header.sticky-scroll & {
            display: none;
        }
    }

    label {
        width: 32px;
        height: 32px;
        padding: 0;
        display: flex;
        background-color: transparent;

        span {
            width: 32px;
            height: 32px;
            align-items: center;
            justify-content: center;

            svg {
                margin-top: 0;
            }
        }

        input {
            display: none;
    
            & + span {
                display: flex;
            }
            & + span + span {
                display: none;
            }

            &:checked {
                & + span {
                    display: none;
                }
                & + span + span {
                    display: flex;
                }
            }
        }
    }
}
