
// ----------------------------------------
//  Bootstrap Utilities API
// ----------------------------------------

$utilities: (
    "font-family": (
        property: font-family,
        class: font,
        values: (
            serif: var(--#{$prefix}font-serif),
            sans-serif: var(--#{$prefix}font-sans-serif),
            monospace: var(--#{$prefix}font-monospace),
            special: var(--#{$prefix}font-special)
        )
    ),
    "font-weight": (
        property: font-weight,
        class: fw,
        values: (
            'thin':         100,
            'extralight':   200,
            'light':        $font-weight-light,     // 300
            'normal':       $font-weight-normal,    // 400
            'medium':       500,
            'semibold':     $font-weight-semibold,  // 600
            'bold':         $font-weight-bold,      // 700
            'extrabold':    800,
            'black':        900,
            'lighter':      $font-weight-lighter,   // Special
            'bolder':       $font-weight-bolder     // Special
        )
    ),
    "text-align": (
        responsive: true,
        property: text-align,
        class: text,
        values: (
            start: left,
            end: right,
            left: left,
            right: right,
            center: center,
            justify: justify
        )
    ),
    "width": (
        responsive: true,
        property: width,
        class: w,
        values: (
            25: 25%,
            50: 50%,
            75: 75%,
            100: 100%,
            auto: auto
        )
    ),
    "dark-color": (
        property: color,
        class: "dark .dark\\:text",
        local-vars: (
            "text-opacity": 1
        ),
        values: map-merge(
            $utilities-text-colors,
            (
                "muted": $text-muted,
                "black-50": rgba($black, .5), // deprecated
                "white-50": rgba($white, .5), // deprecated
                "reset": inherit,
            )
        )
    ),
    "dark-background-color": (
        property: background-color,
        class: "dark .dark\\:bg",
        local-vars: (
            "bg-opacity": 1
        ),
        values: map-merge(
            $utilities-bg-colors,
            (
                "transparent": transparent
            )
        )
    ),
    "dark-border-color": (
        property: border-color,
        class: "dark .dark\\:border",
        local-vars: (
            "border-opacity": 1
        ),
        values: map-merge($utilities-border-colors, (
            gray: $gray-700
        ))
    ),
);


// ----------------------------------------
//  Border Utility
// ----------------------------------------

$utilities-border-colors: map-merge(
    $utilities-border-colors,
    (
        'transparent': transparent
    )
);



// ----------------------------------------
//  Custom Colored Link Classes
// ----------------------------------------

@each $color, $value in $theme-colors {
    .dark .dark\:link-#{$color} {
        color: $value !important;

        @if $link-shade-percentage != 0 {
            &:hover,
            &:focus {
                color: if(color-contrast($value) == $color-contrast-light, shade-color($value, $link-shade-percentage), tint-color($value, $link-shade-percentage)) !important;
            }
        }
    }
}

// ----------------------------------------
//  Custom Utility Classes
// ----------------------------------------

html.no-js .show-on-js {
    display: none !important;
}

html:not(.no-js) .hide-on-js {
    display: none !important;
}

.show-on-dark {
    display: none;

    .dark & {
        display: inherit;
    }
}

.dark .hide-on-dark {
    display: none;
}
