//
// Base styles
//

.btn {
  // scss-docs-start btn-css-vars
    border-radius: var(--bs-btn-border-radius);
  &:hover {
    //
  }

  &.btn-md {
    padding: .45rem .95rem;
    font-size: 0.875rem;
  }

  &.btn-theent {
    background-color: $theent;
  }

  &.btn-outline-theent {
    border: 1px solid $theent;
    background-color: #ffffff;
    &:disabled {
        border: 1px solid #beb4a9;
        background-color: #f7f7f7;
        color: #c0c0b1;
        cursor: not-allowed;
    }
    &:hover {
        color: $white;
        background-color: $theent;
    }
  }
}

// jv small contact form hack
#submit-wrapper-contactForm {
    display: flex;
    justify-content: flex-end;
    .btn {
            border: 1px solid $theent;
            background-color: $white;
            color: $theent;
            margin-top: 1.5rem;
            border-radius: var(--bs-btn-border-radius);
            padding-y: 0.45rem;
            padding-x: 0.95rem;
            font-size: 0.875rem;
        &:hover {
            color: $white;
            background-color: $theent;
        }
    }
}
