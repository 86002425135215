
// ----------------------------------------
//  Extend Bootstrap Spacers
// ----------------------------------------

$spacers: (
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    6: $spacer * 5,
    7: $spacer * 7.5,
    8: $spacer * 10,
);

// ----------------------------------------
//  Dark Variables
// ----------------------------------------

$body-dark-color: $gray-100;
$body-dark-bg: #18181b;
$btn-dark-bg: $theent;

// ----------------------------------------
//  Accordion Variables
// ----------------------------------------

$accordion-button-icon-alt:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$accordion-icon-color}' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z'/></svg>");
$accordion-button-active-icon-alt:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$accordion-icon-active-color}' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z'/></svg>");

$accordion-dark-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$accordion-dark-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;

$accordion-dark-button-icon-alt:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z'/></svg>");
$accordion-dark-button-active-icon-alt:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z'/></svg>");


// ----------------------------------------
//  Alert Variables
// ----------------------------------------

$alert-border-radius: .375rem;

// ----------------------------------------
//  Callout Variables
// ----------------------------------------

$callout-padding-y:                 $spacer;
$callout-padding-x:                 $spacer;
$callout-margin-bottom:             1rem;
$callout-link-font-weight:          700;
$callout-border-width:              3px;
$callout-bg-scale:                  -90%;
$callout-border-scale:              -45%;
$callout-color-scale:               40%;
$callout-dismissible-padding-r:     $callout-padding-x * 3;


// ----------------------------------------
//  Custom Navbar Toggler Icons
// ----------------------------------------

$navbar-menu-toggler-icon: url("data:image/svg+xml,<svg width='100%' height='100%' viewBox='0 0 4 3' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' fill='#{$navbar-light-color}'><path d='M1.94,1.42l-1.28,-1.28l-0.66,0.66l1.94,1.94l0.658,-0.66l0,0.001l1.242,-1.242l-0.66,-0.659l-1.24,1.24Z'/></svg>");
$navbar-dark-menu-toggler-icon: url("data:image/svg+xml,<svg width='100%' height='100%' viewBox='0 0 4 3' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' fill='#{$navbar-dark-color}'><path d='M1.94,1.42l-1.28,-1.28l-0.66,0.66l1.94,1.94l0.658,-0.66l0,0.001l1.242,-1.242l-0.66,-0.659l-1.24,1.24Z'/></svg>");

