
// ----------------------------------------
//  LocalePicker Stylings
// ----------------------------------------

.localepicker {
    button {
        padding: $spacer * 0.39 $spacer * 0.5;
        border: 0;
        opacity: 0.5;
        background-color: transparent;
        border-radius: 5px;
        transition: opacity 200ms ease, background-color 200ms ease;
        background-color: rgba(0, 0, 0, 0.15);

        &:hover,
        &.show {
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.15);
        }
        img {
            border-radius: 3px;
            vertical-align: top;
        }
    }

    .dropdown-menu {
        border: 0;
        background-color: white;
        box-shadow: $box-shadow;
        border-radius: 0;

        img {
            width: auto;
            height: 20px;
            margin: 2px 6px 0 0;
            vertical-align: top;
        }
    }
}

.navbar .localepicker {
    width: 32px;
    height: 32px;
    
    @include media-breakpoint-down(md) {
        top: 7px;
        left: -1.0rem;
        position: absolute;
        width: 32px;
        
        .sticky-header.sticky-scroll & {
            display: none;
        }
    }

    button {
        width: 32px;
        height: 32px;
        padding: 0;
        border-radius: 16px;
        overflow: hidden;
        border: 0 !important;
        box-shadow: none !important;

        @include media-breakpoint-down(md) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &::after {
            content: none;
            display: none;
        }

        img {
            width: 32px;
            height: 32px;
            object-fit: cover;
            object-position: center;
            pointer-events: none;
        }
    }
    
    .dropdown-menu {
        li {
            a {
                font-weight: 400;
                font-family: $font-family-sans-serif;
            }
        }
    }
}

