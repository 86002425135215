
// ----------------------------------------
//  Bootstrap Configuration
// ----------------------------------------

$enable-caret:                  true;
$enable-rounded:                true;
$enable-shadows:                false;
$enable-gradients:              false;
$enable-transitions:            true;
$enable-reduced-motion:         true;
$enable-smooth-scroll:          true;
$enable-grid-classes:           true;
$enable-container-classes:      true;
$enable-cssgrid:                true;
$enable-button-pointers:        true;
$enable-rfs:                    true;
$enable-validation-icons:       true;
$enable-negative-margins:       true;
$enable-deprecation-messages:   true;
$enable-important-utilities:    true;


// ----------------------------------------
//  Modify Gray Color Scheme
// ----------------------------------------

$gray-100: #fafafa;
$gray-200: #f4f4f5;
$gray-300: #e4e4e7;
$gray-400: #d4d4d8;
$gray-500: #a1a1aa;
$gray-600: #71717a;
$gray-700: #52525b;
$gray-800: #3f3f46;
$gray-900: #27272a;
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
) !default;

// theme colour and typog
$theent: #3d3935;
$theent-yello: #bf9850;
$theent-link: #8a7a5c;
$theent-link-hover: #4c402c;
$background-link: #f0f8ff;

// ----------------------------------------
//  Font Variables
// ----------------------------------------
$font-size-base: 1rem;
// ----------------------------------------
//  Extend Font Sizes
// ----------------------------------------

$h1-font-size: $font-size-base * 2.3 !default;
$h2-font-size: $font-size-base * 1.9 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.25 !default;
$h5-font-size: $font-size-base * 1.1 !default;
$h6-font-size: $font-size-base !default;
// scss-docs-end font-variables

$font-sizes: (
    1: $h1-font-size,
    2: $h2-font-size,
    3: $h3-font-size,
    4: $h4-font-size,
    5: $h5-font-size,
    6: $h6-font-size,
    7: 0.9rem,
    8: 0.7rem,
);

$font-family-serif:     "Times New Roman", Times, serif;
$font-family-special:   'nexa_bookregular', sans-serif;
$headings-font-family:  'nexa_bold', sans-serif;

// ----------------------------------------
//  Badge Variables
// ----------------------------------------

$badge-border-radius:               0;

// ----------------------------------------
//  Input | Button Variables
// ----------------------------------------

$input-btn-padding-y:         0.5rem;
$input-btn-padding-x:         1.0rem;


// ----------------------------------------
//  Custom Social Network Colors
// ----------------------------------------

$social-networks: (
    amazon:                         #ff9900,
    deviantart:                     #05cc47,
    discord:                        #5865f2,
    dribbble:                       #ea4c89,
    facebook:                       #1877f2,
    flickr:                         #f40083,
    foursquare:                     #f94877,
    instagram:                      #c32aa3,
    linkedin:                       #0a66c2,
    mastodon:                       #2b90d9,
    medium:                         #02b875,
    pinterest:                      #bd081c,
    quora:                          #aa2200,
    reddit:                         #ff4500,
    rss:                            #f26522,
    slack:                          #4a154b,
    soundcloud:                     #ff5500,
    spotify:                        #1ed760,
    steam:                          #171A21,
    telegram:                       #0088cc,
    tumblr:                         #35465d,
    twitch:                         #9146ff,
    twitter:                        #1da1f2,
    vimeo:                          #1ab7ea,
    vk:                             #4a76a8,
    whatsapp:                       #25d366,
    youtube:                        #ff0000,
);
