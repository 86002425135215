
// ----------------------------------------
//  Accordion Stylings
// ----------------------------------------

.dark .accordion {
    --#{$prefix}accordion-bg: var(--#{$prefix}gray-900);
    --#{$prefix}accordion-color: var(--#{$prefix}gray-400);
    --#{$prefix}accordion-border-color: var(--#{$prefix}gray-900);
    --#{$prefix}accordion-btn-color: var(--#{$prefix}gray-400);
    --#{$prefix}accordion-btn-bg: #{$accordion-button-bg};
    --#{$prefix}accordion-btn-icon: #{escape-svg($accordion-dark-button-icon)};
    --#{$prefix}accordion-btn-active-icon: #{escape-svg($accordion-dark-button-icon)};
    --#{$prefix}accordion-btn-focus-border-color: #{$accordion-button-focus-border-color};
    --#{$prefix}accordion-btn-focus-box-shadow: #{$accordion-button-focus-box-shadow};
    --#{$prefix}accordion-active-color: var(--#{$prefix}white);
    --#{$prefix}accordion-active-bg: var(--#{$prefix}gray-800);

    .accordion-button {
        &::after {
            opacity: 0.5;
        }

        &:not(.collapsed) {
            &::after {
                opacity: 0.75;
            }
        }
    }
}

.accordion-body {
    & > *:last-child {
        margin-bottom: 0;
    }
}

.accordion-icons-hide {
    .accordion-button::after {
        content: none;
        display: none;
    }
}

.accordion-icons-alt {
    --#{$prefix}accordion-btn-icon: #{escape-svg($accordion-button-icon-alt)};
    --#{$prefix}accordion-btn-active-icon: #{escape-svg($accordion-button-active-icon-alt)};
}
.dark .accordion-icons-alt {
    --#{$prefix}accordion-btn-icon: #{escape-svg($accordion-dark-button-icon-alt)};
    --#{$prefix}accordion-btn-active-icon: #{escape-svg($accordion-dark-button-active-icon-alt)};
}

.accordion-icons-left {
    .accordion-button {
        justify-content: flex-end;
        flex-direction: row-reverse;

        &::after {
            margin-left: 0;
            margin-right: $spacer* 0.5;
        }
    }
}

#accordion-conditions .accordion-item {
    scroll-margin-top: 300px;
}


.accordion-clean {
    @extend .accordion-flush;

    .accordion-item {
        border: 0;
        background-color: transparent;
    }

    .accordion-button {
        padding: $spacer * 0.25 0;
        background-color: transparent;

        &::after {
            margin-right: $spacer;
        }
        &:focus {
            border-color: transparent;;
            box-shadow: none;
        }
        &:not(.collapsed) {
            background-color: transparent;
            border-color: transparent;
            box-shadow: none;
        }
    }

    .accordion-body {
        padding-top: $spacer * 0.75;
        padding-left: $spacer * 1.75;
        padding-bottom: $spacer * 2.0;
    }
}
