// ----------------------------------------
//  Bootstrap Card Stylings
// ----------------------------------------

.card {
    width: 100%;

    .card-body,
    .card-text {
        & > *:last-child {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(md) {
        &.card-small {
            width: 16rem;
        }
        &.card-medium {
            width: 24rem;
        }
        &.card-large {
            width: 32rem;
        }
        &.card-fullwidth {
            width: 100%;
        }
    }
}

.dark .card:not(.card-simple) {
    --#{$prefix}card-border-color: #{$gray-700};
    --#{$prefix}card-color: #{$card-color};
    --#{$prefix}card-bg: #{$gray-900};
}

.blog-layout {
    .card {
        border-radius: 13px;
        box-shadow: 0px 0px 22px rgba(0,0,0,0.07);
        border-color: #EBEBEB;
        overflow: hidden;

        .card-banner {
            max-width: 710px;
            background-size: cover;

            &.banner-lg {
                height: 440px;
            }
        }

        &.post-card {
            .blog-post-title {
                font-size: 1.9rem;
                color: $theent;
                margin: 1rem 0;

                a {
                    color: #000;
                    text-decoration: none;
                }
            }

            .blog-post-meta .meta-item {
                display: inline-block;
                position: relative;
                color: #A2A2A2;
                font-size: 14px;
            }

            .post-card .blog-post-meta .meta-item {
                display: inline-block;
                position: relative;
                color: #A2A2A2;
                font-size: 14px;
            }

            .blog-post-meta .meta-divider {
                width: 20px;
                text-align: center;
            }

            .card-body {
                padding: 1.985rem;
                .user-avatar {
                    margin-right: 1.5rem;
                    img {
                        width: 95px;
                    }

                }
                .user-details {
                    padding: 15px 0 0 0;

                    p {
                        color: #A1A1A1;
                    }
                }

                .share-button {
                    .btn {
                        position: relative;
                        border-color: transparent;
                        background: linear-gradient(102.01deg,#5799eb 0.3%,#9f74fb 106.31%);
                        padding-left: 47px;
                        padding-right: 25px;
                        &.btn-pill {
                            border-radius: 100px;
                        }
                        &:before {
                            content: "";
                            position: absolute;
                            left: 17px;
                            top: 12px;
                            width: 14px;
                            height: 14px;
                            background-repeat: no-repeat;
                            background-size: 14px 14px;
                            background-image: url(../images/icons/icon-share.png);
                            }
                    }

                    .share-button-popover {
                        padding: 0;
                        margin: -1rem;
                        overflow: hidden;
                        border-radius: 8px;
                        .nav-link {
                            padding: 10px 15px;
                            color: #343F52;
                            text-decoration: none;
                        }

                        .nav-link:hover {
                            color: #fff;
                            background: #3097d1;
                        }
                    }

                    // .btn {
                    //     border-color: transparent;
                    //     background: linear-gradient(102.01deg,#5799eb 0.3%,#9f74fb 106.31%);}
                    // }
                }
            }

            .card-footer {
                background-color: #fff;
                padding: 1rem 1.5rem;
                // the calendar in the blog card footer
                .text-icon.text-icon-date:before {
                    background-image: url(../images/icons/icon-calendar.png);
                }
                // the user in the blog card footer
                .text-icon.text-icon-author:before {
                    background-image: url(../images/icons/icon-user.png);
                }

                .text-icon:before {
                        content: "";
                        position: absolute;
                        left: 0px;
                        top: -1px;
                        width: 16px;
                        height: 16px;
                        background-repeat: no-repeat;
                        background-size: 16px 16px;
                    }

                .text-icon {
                    position: relative;
                    display: inline-block;
                    padding-left: 24px;
                    line-height: 16px;
                }

                a {
                    color: #000;
                    text-decoration: none;
                }
            }
        }
    }
}

// ----------------------------------------
//  Simple Card Stylings
// ----------------------------------------

.card.card-simple {
    border: 0;
    padding: $spacer * 2;
    margin-bottom: $spacer * 1.5;
    background-color: $gray-100;
    &.white {
        background-color: $white;
    }

    .dark & {
        background-color: $gray-900;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .card-img-top {
        width: auto;
        margin-top: -($spacer * 2);
        margin-left: -($spacer * 2);
        margin-right: -($spacer * 2);
        margin-bottom: $spacer * 2;
    }

    .card-header,
    .card-body {
        border: 0;
        padding: 0;
        background-color: transparent;
    }

    .card-header {
        margin-bottom: $spacer;
    }

    .card-title,
    .card-subtitle {
        display: block;
        text-align: left;
        line-height: 1.4rem;
        font-family: $font-family-special;
    }
    .card-title {
        color: $gray-700;
        font-size: 1.4rem;

        .dark & {
            color: $gray-400;
        }
    }
    .card-subtitle {
        color: $gray-600;
        font-size: 0.9rem;
    }

    .card-body .post-button:not(:last-child) {
        margin-bottom: $spacer;
    }

    .card-body .list-group-flush {
        .list-group-item {
            padding: 0;
            background-color: transparent;
        }

        a {
            color: var(--#{$prefix}dark);
            padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
            display: block;
            text-decoration: none;
            transition: color 0.20s ease, background-color 0.20s ease;
            background-color: $gray-300;

            .dark & {
                color: var(--#{$prefix}light);
                background-color: $gray-800;
            }

            &:hover {
                color: var(--#{$prefix}light);
                background-color: var(--#{$prefix}dark);
            }
        }
    }

    .card-footer {
        margin-top: $spacer;
        padding: $spacer * 2 0 0 0;
        background-color: transparent;
    }
}
.card-group > .card.card-simple {
    margin-bottom: 0;
}
