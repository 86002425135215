// ----------------------------------------
//  Additional Font Families
// ----------------------------------------

@font-face {
    font-family: 'nexa_regular';
    src: url('fonts/nexa-regular-webfont.woff2') format('woff2'),
         url('fonts/nexa-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nexa_black';
    src: url('fonts/nexa-black-webfont.woff2') format('woff2'),
         url('fonts/nexa-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nexa_bold';
    src: url('fonts/nexa-bold-webfont.woff2') format('woff2'),
         url('fonts/nexa-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nexa_light';
    src: url('fonts/nexa-light-webfont.woff2') format('woff2'),
         url('fonts/nexa-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nexa_heavyregular';
    src: url('fonts/nexa-heavy-webfont.woff2') format('woff2'),
         url('fonts/nexa-heavy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



@font-face {
    font-family: 'nexa_bookregular';
    src: url('fonts/nexa-book-webfont.woff2') format('woff2'),
         url('fonts/nexa-book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
