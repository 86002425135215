// ----------------------------------------
//  Additional Global Custom Properties
// ----------------------------------------


:root {
    --#{$prefix}font-serif: #{inspect($font-family-serif)};
    --#{$prefix}font-special: #{inspect($font-family-special)};

    // --bs-nav-pills-link-active-bg
}

// ----------------------------------------
//  Additional Global Custom Properties
// ----------------------------------------

body.dark {
    --#{$prefix}body-bg: #{$body-dark-bg};
    --#{$prefix}body-bg-rgb: #{to-rgb($body-dark-bg)};
    --#{$prefix}body-color: #{$body-dark-color};
    --#{$prefix}body-color-rgb: #{to-rgb($body-dark-color)};
}

// ----------------------------------------
//  Remove Last-Child Margin
// ----------------------------------------

.content > *:last-child {
    margin-bottom: 0;
}

// ----------------------------------------
//  Responsive Video Tag
// ----------------------------------------

video {
    @include media-breakpoint-down(md) {
        Width: 100% !important;
        height: auto !important;
    }
    margin-bottom: $spacer * 2;
}
