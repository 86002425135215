// ----------------------------------------
//  The Ent Custom Stylings
// ----------------------------------------
    html, body {
        margin: 0;
        padding: 0;
        min-height:100vh;
    }

    body {
        &::-webkit-scrollbar {
            width: 1em;
        }

        &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }

        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
        }
    }

    // main text style
    p, li, .citation-text {
        font-family: 'nexa_bookregular', sans;
    }

    b, strong {
        font-family: 'nexa_bold';
    }

    header#pageHeader {
        > .container-fluid {
            padding: 0;
            @include media-breakpoint-down(lg) {
                #pageHeaderMenu {
                    padding: 1rem 4rem;
                }
            }
        }
    }


    // all jump links to secitons

    section,
    div#ent-contact-form {
        scroll-margin-top: 15rem;
    }

    // :target {

    // }

    // square bullets for ul except for any nav item
    div:not(.navbar-menu, .navbar-actions) {
        > ul  {
            list-style-type: square;
            list-style-position: outside;
            margin: 0 0 1.5rem 1rem;
            > li {
                margin-bottom: .325rem;
                // square bullets except bootstrap nav elements
                > ul {
                    list-style: none;
                    margin: .325rem 0 0 0;
                    li:before {
                        display: inline-block;
                        content: "";
                        background: url(/storage/app/media/sq-bullet.gif) no-repeat left center;
                        height: 1rem;
                        width: 0.75rem;
                        vertical-align: middle;
                        margin-bottom: .1rem;
                    }
                }
            }
            //
            &.nav-pills {
                list-style-type: none;
                list-style: none;
                margin: 0;
                li {
                    width: auto;


                }

                &.public-private {
                    li {
                        min-width: 124px;

                    }
                }

                &.loc-maps {
                    li {
                        min-width: 200px;
                        button {
                            width: 97%;
                            margin: 0 auto;
                        }
                    }
                }

                .nav-link {
                    background-color: $gray-200;
                    &:hover {

                        background-color: $gray-500;
                    }
                    &.active {
                        color: $white;
                        background-color: $gray-700;
                    }

                    &:not(.active) {
                        color: $theent;
                    }
                }
            }
        }
    }

    // page-content wrapper implements sticky footer
    #page-content {
        min-height: 100vh;
    }

    // section gneral
    section {
        position: relative;
        width: 100%;

        h2 {
            margin-top: 2rem;
        }
        // main text
        p {
            line-height: 1.8rem;
            color: $theent;
        }

        ul {
            padding-left: 0;
        }

        a {
            text-decoration: underline;
        }

        .citation-text {
            margin-bottom: 1.5rem;
            padding-bottom: 1rem;
            border-bottom: 1px dotted $gray-400;
        }

        .container {
            position: relative;
            width: 100%;
            // ul {
            //     list-style-type: none;
            //     li {
            //         margin-bottom: .95rem;
            //     }
            // }

            .map {
                height: 420px;
            }
        }

        .heading-entbg {
            display: inline-block;
            padding: .25rem;
            background-color: $gray-200;
            border-radius: .25rem;
            margin-bottom: 1.2rem;
        }

        &.regional-accommodation {

            // body {
            //     font-size: 2em;
            //     text-align: center;
            // }

            a {
                text-decoration: none;
                color: inherit;
                line-height: 1;
            }

            p {
                padding: .25rem;

                &.closed {
                    color: red;
                    font-size: .75rem;
                    text-decoration: underline overline;
                    text-underline-offset: .25rem;
                    margin: .5rem 0;
                }
            }

            #accommodation {
                a {
                    color: $black;
                    padding: .25rem;
                    background: linear-gradient(0deg, $theent, $theent ) no-repeat right bottom / 0 100% ;
                    // TODO set var
                    background-color: #f3f2ef; //$background-link;
                    transition: color 450ms, background-size 250ms;
                    // --bg-h: 100%;
                    &:hover {
                        color: $white;
                    }
                }
                a:where(:hover, :focus-visible) {
                    background-size: 100% 100%;
                    background-position-x: left;
                }
            }
        }
        &#conditions-treated {
            min-height: 600px;
        }

        //
        &#conditions-treated,
        &#news-layout {

            p:has( + ul) {
                margin-bottom: 0;
            }

            h5 {
                font-size: 1rem;;
                font-family: 'nexa_bold', sans;
                span.p {
                    font-family: 'nexa_regular'!important;
                }
            }
            // BS overwrite
            h2 {
                margin-top: 0;

                .accordion-button {
                    font-family: 'nexa_regular', sans-serif;
                    font-size: 1.5rem;

                    &:focus {
                        box-shadow: none; //var(--bs-accordion-btn-focus-box-shadow);
                    }

                    &:not(.collapsed) {
                        color: $theent; //var(--bs-accordion-active-color);
                        background-color:  #f3efd5; //var(--bs-accordion-active-bg);
                    }
                }
            }

            .accordion-body {
                h3,
                h4 {
                    font-family: 'nexa_regular';
                }

                h2 {
                    font-size: 1.55rem;
                }

                h3 {
                    font-size: 1.25rem;
                }

                h4 {
                    font-size: 1.1rem;
                }
            }
        }

        //
        &.section-footer-meta {
            max-height: 370px;
        }
    }

    // page specific
    .page-home #page-content {

        > section {
            width: 100%;
            // padding: 3rem 1rem!important;

            h2 {
                margin-top: 0 !important;
                font-family: 'nexa_bold', sans;
                font-size: 1.5rem
            }

            h3 {
                // margin-top: 0 !important;
                // font-family: 'nexa_bold', sans;
                font-size: 1.1rem
            }

            // front page first panel
            &#ent-hero {
                display: flex;
                height: clamp(424px, 50vw, 624px);
                flex-direction: column;
                flex-wrap: unset;
                align-items: center;
                justify-content: center;
                background-image: url('/storage/app/media/home/home-hero-bkgrnd.jpg');
                background-size: cover;

                #ent-hero-title {
                    position: absolute;
                    height: 100%; // <- would be 100% but for margin-top
                    width: auto;
                    text-align: center;
                    color: $black; //$theent;
                    font-family: 'nexa_light';
                    font-size: clamp(47px, 9.2vw, 140px);
                    text-transform: uppercase;
                    letter-spacing: -2px;
                    line-height: .91;

                    @include media-breakpoint-up(md) {
                        letter-spacing: -.5px;
                    }

                    & > div {
                        margin-top: 12%;

                        @include media-breakpoint-up(sm) {
                            margin-top: 11%;
                        }

                        @include media-breakpoint-up(md) {
                            margin-top: 10%;
                        }

                        @include media-breakpoint-up(lg) {
                            margin-top: 9%;
                        }

                        @include media-breakpoint-up(xl) {
                            margin-top: 8%;
                        }

                        @include media-breakpoint-up(xxl) {
                            margin-top: 7%;
                        }
                    }

                    #ent-intro {
                        position: absolute;
                        bottom: 1rem;
                        left: 1rem;

                        @include media-breakpoint-up(sm) {
                            left: 2.5vw;
                        }
                        @include media-breakpoint-up(md) {
                            left: 3vw;
                        }

                        @include media-breakpoint-up(lg) { //≥992px
                            left: 3.5vw;
                        }

                        @include media-breakpoint-up(xl) { //≥1200px
                            left: 3.2vw;
                        }

                        @include media-breakpoint-up(xxl) { //≥1400px
                            left: 3.1vw;
                        }
                        @media only screen and (min-width: 1600px) {
                            left: 2.8vw;
                        }
                        @media only screen and (min-width: 1800px) {
                            left: 2.7vw;
                        }
                        @media only screen and (min-width: 2000px) {
                            left: 2.5vw;
                        }


                        @media only screen and (min-width: 2200px) {
                            left: 2.2vw;
                        }

                        @media only screen and (min-width: 2400px) {
                            left: 2vw;
                        }

                        max-width: 460px;
                        text-align: left;
                        text-transform: none;
                        letter-spacing: 0;
                        h2 {
                            font-size: 1.5rem;
                            color: $theent;
                        }

                        p {
                            font-size: 1rem;;
                        }
                    }
                }

                .tag-cloud-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                }

                .tagcloud {
                    max-width: 410px;
                    font-family: 'nexa_bookregular';
                    font-size: .8rem;
                    text-transform: uppercase;
                    color: #8cb8ec;
                    }
                    .tagcloud--item:hover {
                    color: #36454F;
                }

                @include media-breakpoint-up(md) {
                    .tagcloud {
                        max-width: 640px;
                        font-size: .9rem;
                        // width: 640px!important;
                    }
                }
            }

            // front page second panel
            &#ent-front {
                // max-height: 1024px;
                @include media-breakpoint-down(md) {
                    .dotted-bg {
                        display: none;
                    }
                }

                .dotted-bg {
                    position: relative;
                    --dotted-opacity: .7;
                    --dotted-top: 0;
                    --dotted-right: 0;
                    --dotted-left: auto;
                    --dotted-size: 190px;
                    &::before {
                        content: "";
                        position: absolute;
                        display: block;
                        top: var(--dotted-top);
                        left: var(--dotted-left);
                        right: var(--dotted-right);
                        width: var(--dotted-size);
                        height: var(--dotted-size);
                        background-size: 20px 20px;
                        background-position: center;
                        transform: translate(35px, -25px);
                        background-image: radial-gradient(rgba($theent-yello, var(--dotted-opacity)) 25%, transparent 25%);
                        z-index: auto;
                    }

                    #ent-slide {
                        img {
                            max-height: 820px;
                        }
                    }
                }


                p {
                    line-height: 1.5rem;
                }

                h1 {
                    font-family: 'nexa_regular', sans;
                    font-size: clamp(5.6vw, 5.8vw, 6vw);
                    span {
                        display: inline-block;
                        clear: both;
                    }
                }

                @include media-breakpoint-up(md) {
                    h1 {
                        font-size: clamp(3.4vw, 3.8vw, 3.8vw);
                    }
                }

                @include media-breakpoint-up(lg) {
                    h1 {
                        font-size: clamp(3.4vw, 3.8vw, 3.8vw);
                    }
                }

                @include media-breakpoint-up(xl) {
                    h1 {
                        font-size: clamp(3.4vw, 3.8vw, 3.8vw);
                    }
                }

                @include media-breakpoint-up(xxl) {
                    h1 {
                        font-size: clamp(2.4vw, 2.5vw, 3vw);
                    }
                }
            }

            &#ent-contact {
                // max-height: 620px;
                background-color: $gray-100;
            }
        }
    }

    section:not(#ent-front, #ent-hero, #ent-message, #ent-contact, #ent-map) {
        max-width: 1140px;
    }

    // TO DO do we need the post.scss ?
    // mainselector is post-sngle, we don't have that in the frontend

    // news posts
    // mainselector is page-news-post - twice ?
    article {
        blockquote {
            padding: 0 1.5rem .5rem 1.5rem;
            margin-bottom: 1.75rem;
            border-bottom: 1px dotted $gray-400;
        }
    }


    // the ul of the main nav
    .navbar-main {
        padding: 0 0.5rem;
        .navbar-collapse {
            // vertical menu is showing
            .navbar-nav:first-child {
                // align-items: center;
                // padding: .25rem;
                // font-family: "nexa_bookregular";
                > li {
                    .nav-link {
                        padding: 0;
                    }
                    &:first-child {
                        // color: $theent;
                        a {
                            color: transparent!important;
                            font-size: 1rem;
                            &:before {
                                //display: inline-flex;
                                content:"Home";
                                background-image: none;
                                color: $theent;
                                // background-size: 28px 28px;
                                // height: 28px;
                                // width: 28px;
                            }
                        }
                    }
                }
            }
            // horizontal menu is showing
            @include media-breakpoint-up(lg) {
                .navbar-nav:first-child {
                    // align-items: center;
                    padding: .25rem;
                    font-family: "nexa_regular";
                    > li {
                        .nav-link {
                            padding: 0.5rem 1rem;
                            // &:first-child {
                            //     padding-left: 0;
                            // }
                        }
                        &:first-child {
                            // color: $theent;
                            a {
                                color: transparent!important;
                                font-size: 1px;
                                &:before {
                                    display: inline-flex;
                                    content:"";
                                    background-image: url('/themes/theent/assets/images/ent-menu-ico.svg');
                                    background-size: 28px 28px;
                                    height: 28px;
                                    width: 28px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .navbar-subnav {
            top: 90%;
            left: 60%;
            background-color: #fafafa;

            .nav-item:first-child::before {
                border-color: transparent transparent #fafafa transparent;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        #pageActions {
            .navbar-nav {
                justify-content: flex-start;
            }
        }

        .header-extended .navbar-actions {
            right: 0;
        }
    }

    @media (min-width: 992px) {
        .text-stretch {
        position: relative;
        width: calc(100% + 200px);
        z-index: 1;
        }
    }

    // news - the tags
    .sidebar-tags {
        display: flex;
        flex-direction: column;

        .news-tags {
                display: inline-block;
                padding: .1rem .5rem;
                font-size: .8rem;;
                margin: .5rem;
                text-decoration: none;
                color: $theent;
                border: 1px solid $white;
                border-radius: 3px;
                outline: 1px solid $gray-300;
                background: rgb(237,237,237);
                background: linear-gradient(0deg, rgba(237,237,237,1) 0%, rgba(251,251,251,1) 100%);

                &.active {
                    background: rgb(236,231,184);
                    background: linear-gradient(0deg, rgba(236,231,184,1) 0%, rgba(255,253,236,1) 100%, rgba(251,251,251,1) 100%);
                }
        }
    }

    // share popover
    .popover {
        &.bs-popover-auto {
            border-radius: .573rem;
            .popover-body {
                padding: 0;

                .share-button-popover {
                    ul {
                        list-style-type: none;
                        margin: 0;
                        padding: 0;
                        li {
                            margin: 0;
                            padding: 0;
                        }
                        li:first-child {
                            border-top-left-radius: .573rem;
                            border-top-right-radius: .573rem;
                        }
                        li:last-child {
                            border-bottom-left-radius: .573rem;
                            border-bottom-right-radius: .573rem;
                        }
                        li:hover {
                            background-color: #0d6efd;
                            a {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    // password eye
    #toggle-password {
        position: absolute;
        margin-top: 32px;
        left: 93%;
        cursor: pointer;
        &:before {
            transform: scale(1.7)
        }
    }

    .page-team {
        section#team {
            .row:first-child {
                margin: 0;
                > div {
                    padding: 0;
                    .row > div {
                        height: 100%;
                    }
                }
            }

            .card {
                border: 0 !important;

                .col-lg-7 {

                    .card-body {
                        height: 100%;
                        padding: 0 .5rem;
                        margin: 0 auto;
                        p.card-title {
                            font-size: 1.1rem;
                            line-height: 0;
                            color: #40413c;
                        }
                        .card-text {
                            font-size: 14px;
                        }
                    }

                    position: relative;
                    min-height: 229px;
                    padding: 0 0 0 .5rem!important;
                    &.order-1,
                    &.order-lg-2 {
                        padding: 0 .5rem 0 0!important;
                    }
                }

                .col-lg-5 {
                    padding: 0!important;
                }

                a.social-icon {
                    display: inline-block;
                    img {
                        width: 24px;
                    }
                }

                // standard
                @media (min-width: 992px) {
                    .card-body {
                        max-width: 550px;
                        padding: 0;
                        b.card-title {
                            line-height: 0;
                        }
                        .card-text {
                            font-size: 15px;
                            line-height: 1.7rem;
                        }
                    }
                }

                @media (min-width: 1200px) {
                    a.social-icon {
                        margin-top: 1.2rem;
                    }
                    img {
                        width: 27px;
                    }

                    .card-body {
                        max-width: 650px;
                        margin: 0;
                        b.card-title {
                            font-size: 17px;
                            line-height: 1.8 rem;
                        }
                        .card-text {
                            font-size: 16px;
                            line-height: 2rem;
                        }
                    }
                }

                @media (min-width: 1400px) {
                    a.social-icon {
                        margin-top: 1rem;
                        img {
                            width: 30px;
                        }
                    }

                    .card-body {
                        max-width: 750px;
                        margin: 0;

                        b.card-title {
                            font-size: 18px;
                            line-height: 2.2rem;
                        }
                        .card-text {
                            font-size: 16px;
                            line-height: 2rem;
                        }
                    }
                }

                .ent-hero {
                    max-width: 610px;
                    border-radius: 7px;
                    width: 100%;
                    aspect-ratio: 1.56;
                }

                .vid-icon {
                    position: relative;
                    display: block;
                    width: 50px;
                    height: 50px;
                    margin-left: -50px;
                    margin-top: 0;
                    border-top-right-radius: 7px;
                    background-color: #c5b768;
                    padding: 0.25rem;
                    &>img {
                        width: 42px;
                    }
                }

                .bio {
                    text-decoration: none;
                    padding: 0 0.5rem 0.15rem 0.5rem;
                    background-color: #a9a9a9;
                    color: white;
                    margin: 0 1rem;
                    border-radius: 0.215rem;
                    font-size: 15px;
                }
            }
        }
    }

    #vid-pop {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: .98;
        top: 0;
        bottom: 0;
        z-index: 10000000;

        header + div {
            max-width: 850px;
            width: 100%;
        }
    }

    // patients resources
    .all-locations {
        width: 94%;
        padding: 0!important;
        > p.card-text {
            padding: 1rem;
        }
        // @include media-breakpoint-up(sm) {
        //     width: 41%;
        // }

        // @include media-breakpoint-up(md) {
        //     width: 43%;
        // }

        @include media-breakpoint-up(lg) {
            width: 45%;
        }
    }


    // pagesearch at entheader.htm
    #search-page {
        // flexsearch form and resullts
        #search-form {
            top: 320px;
            min-width: 430px;
            &.short {
                // top: unset;
               top: 220px;
            }
            &.x-short {
                // top: unset;
                top: 100px;
            }
        }

        #search-results-panel {
            top: 380px;
            max-width: 720px;
            width: 88%;
            background-color: $white;
            border-radius: var(--bs-border-radius);

            &:after {
                bottom: 100%;
                left: 50%;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(255, 255, 255, 0);
                border-bottom-color: #ffffff;
                border-width: 16px;
                margin-left: -16px;
            }

            article {
                &:not(last-child) {
                    padding-bottom: .5rem;
                    border-bottom: 1px dotted $gray-300;
                }

                &:not(first-child) {
                    padding-top: .5rem;
                }

                a {
                    display: inline-block;
                    text-decoration: none;
                    color: $theent;
                    padding: .75rem;
                    border-radius: var(--bs-border-radius);

                    .content {
                        font-family: 'nexa_bookregular';
                        margin-bottom: 1rem;
                    }
                    &:hover {
                        background-color: $gray-300;
                    }
                }
            }
        }

        #search-form,
        #search-results-panel {
            position: absolute;
        }
    }

    // footer especially during search
    #pageFooter {
        z-index:10000;
    }

    //
    #scf-contactForm {
        label {
            padding: 0.75rem 0 0 0;
        }
    }

    #contact-box {
        border-radius: 8px;
        background-color: $gray-200;
        max-height: 27rem;
    }

    // forms
    a:has( > span.icomoon-use-form ),
    a:has( > span.icomoon-edit ) {
        text-decoration: none;
    }
    // patient forms
    .patient-forms {
        margin-top: 2rem;
        margin-bottom: 3rem;
        .form-group {
            display: flex;
            flex-flow: row wrap;
            margin-bottom: 1.2rem;

            input[type=text]:required,
            input[type=email]:required,
            input[type=date]:required,
            input[type=number]:required,
            input[type=select]:required {
                border-bottom: 1px solid red;
            }
            input::placeholder {
                opacity: 0.85;
                color: $gray-500;
            }

            select {
                -webkit-appearance: menulist !important;
                -moz-appearance: menulist !important;
                appearance: menulist !important;
            }

            label {
                font-size: 1rem;
                min-height: 1px;
                padding-right: 15px;
                padding-left: 15px;
                // dimensioning our pseudo form element types
                input[type=checkbox] {
                    height: 2.1rem;
                    width: 1.3rem;
                    zoom: 1.1;
                }

                &.radio-inputs {
                    display: flex;
                    align-items: center;
                    font-size: 16px;

                    input[type=radio] {
                        margin: 0 0 0 0.25rem !important;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                        height: 1.9rem !important;
                        width: 4rem !important;
                    }
                }

            }

            legend {
                font-size: 1.1rem;
                padding: 1.3rem;
            }
            // consent
            &.consent-group {
                flex-wrap: nowrap;
                padding: 1rem 0;
                border-top: 1px solid #d6d0d8;
                border-bottom: 1px solid #d6d0d8;
                label {
                    margin: 0 1rem 0 0;
                }
            }

            // required checkbox
            input[id="consent"] + label {
                width: 1.5rem;
                height: 1.5rem;
                border: 2px solid red;
                border-radius: 3px;
                cursor: pointer;
                margin: 0.75rem;
                padding: 0.5rem;
            }

            input[id="consent"]:checked + label:after {
                position: relative;
                top: -11px;
                left: -5px;
                content: '\2714';
                font-size: 14px;
                font-size: 14px;
            }


            input[id="consent"] {
                display: none;
            }

            &.turing {
                height: 1px;
                visibility: hidden;
            }
        }

        .form-control {
            -webkit-appearance: auto;
            -moz-appearance: auto;
            appearance: auto;
            height: 2.2rem;
            margin-bottom: 1rem;
            padding: 5px;
            color: $theent;
            background-color: #fff;
            background-image: none;
            border: 1px solid $gray-400;
            -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
            -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
            transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;

            &input[type=text] {
                -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
                box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
            }
        }

        // specific to this form
        &#ciform {
            .form-group {
                margin-bottom: 0;

                label {
                    line-height: 2.1rem;
                }

                #date {
                    margin-bottom: 1.5rem;
                }
                input[type=checkbox] {
                    float: left;
                    margin: 0 1rem .5rem 0;
                }
            }
        }
    }

    // the public privvate comparison
    .comparo_labels {
        padding-right: 15px;
        padding-left: 15px;

        div {
            color: #fff;
            min-width: 289px;
            text-transform: uppercase;
            padding: .75rem 2rem;
            text-align: center;
            &:nth-child(1) {
                background-color: #c2b24b;
            }
            &:nth-child(2) {
                background-color: #4255e6;
            }
        }
    }

    .vc_tta-tabs-list {
        li {
            margin: 6px 0 0 3px !important;
            a {
                margin-bottom: 3px !important;
                border: none !important;
                min-width: 160px;
                text-align: center;
            }
        }
    }

    .vc_tta-title-text {
        font-family: "Open Sans" !important;
        font-size: 21px !important;
        font-weight: 400 !important;
        letter-spacing: 0 !important;
    }
    .comparo {
        position: relative;
        height: 100%;
        max-height: 100%;
        list-style: none;
        margin-top: 2rem;
        padding: 0 !important;
        h2 {
            display: block;
            position: relative;
            color: $theent;
            margin: 0;
            padding: 1rem 1.5rem;
            cursor: pointer;
            text-align: center;
            border: 5px solid $gray-300;
            background-color: #fff;
        }
        h2.active {
            color: #fff;
            border: 5px solid #7d7b7d;
            background-color: #7d7b7d;
        }
        &::before {
            position: absolute;
            content: "";
            display: block;
            margin-top: .2rem;
            left: 50.25%;
            width: 0;
            height: 100%;
            max-height: 100%;
            border-left: 7.2px dotted #b9b1c5;
        }
        >li {
            margin-bottom: 5.865rem;
        }
    }

    h2 {
        line-height: 32px;
        span {
            float: right;
            vertical-align: middle;
            &::before {
                font-size: 3.5rem;
            }
        }
    }

    .arrow {
        &:before {
            font-family:'Bootstrap-icons';
            content: "\f128";
        }

        &.open:before {
            font-family:'Bootstrap-icons';
            content: "\f148";
        }
    }

    .collapsible {
        visibility: hidden;
        overflow: hidden;
        max-height: 0;
        transition: all .2s ease-in-out;
        > div {
            display: flex;
            flex-wrap: nowrap;
            align-items: stretch;
            margin: 4.5rem 0 4.5rem 0;
            >div {
                position: relative;
                flex-grow: 1;
                flex-basis: 50%;
                padding: 2.5rem;
                &:after {
                    content: "";
                    position: absolute;
                    top: 1rem;
                    width: 0;
                    border-top: 17px solid transparent;
                    border-bottom: 17px solid transparent;
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 1rem;
                    width: 0;
                    border-top: 17px solid transparent;
                    border-bottom: 17px solid transparent;
                }
                &:nth-child(1) {
                    background-color: #f8f6cc;
                    margin-right: 3rem;
                    &:after {
                        border-left: 17px solid #f8f6cc;
                        right: -17px;
                    }
                }
                &:nth-child(2) {
                    background-color: #daf0ff;
                    margin-left: 3rem;
                    &:before {
                        border-right: 17px solid #daf0ff;
                        left: -17px;
                    }
                }
            }
        }
    }

[aria-expanded=true] + .collapsible {
    visibility: visible;
    visibility: visible;
    max-height: 100%;
    height: auto;
}
// @media only screen and (max-width: 960px) {
//     .comparo_labels {
//         flex-direction: column;
//         justify-content: center;
//         width: 100%;
//         span {
//             width: 100%;
//         }
//     }
//     .collapsible {
//         >div {
//             flex-wrap: wrap;
//             div {
//                 flex-basis: 100%;
//             }
//             >div {
//                 margin: 0 !important;
//                 &:after {
//                     display: none;
//                 }
//                 &:before {
//                     display: none;
//                 }
//             }
//         }
//     }
//     .comparo {
//         &::before {
//             display: none;
//         }
//     }
// }
// @media only screen and (max-width: 768px) {
//     .comparo {
//         >li {
//             width: 98%;
//         }
//         h2 {
//             font-size: 21px;
//         }
//     }
//     h2 {
//         span {
//             &::before {
//                 top: 25%;
//                 font-size: 3.5rem;
//                 right: .25em;
//             }
//         }
//     }
// }

